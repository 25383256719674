import React, { useEffect } from "react";

const Active = ({ className, title }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      symbols: [
        {
          description: "",
          proName: "BYBIT:BNBUSDT.P",
        },
        {
          description: "",
          proName: "BYBIT:OPUSDT.P",
        },
        {
          description: "",
          proName: "BYBIT:MKRUSDT.P",
        },
        {
          description: "",
          proName: "BYBIT:LTCUSDT.P",
        },
      ],
      showSymbolLogo: true,
      isTransparent: false,
      displayMode: "regular",
      colorTheme: "dark",
      locale: "en",
    });
    const container = document.getElementById("tradingview-widget-container");
    if (container && !container.querySelector("script[src='https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js']")) {
      container.appendChild(script);
    }
  }, []);

  return (
      <div className={className}>
        <div className="w-full mb-6">
          <h2 className="text-white text-[32px] text-center font-jura font-bold xl:text-[40px] w-full">
            Активы которыми мы торгуем
          </h2>
        </div>
        <div className="w-full flex justify-center">
          <div
              className="tradingview-widget-container"
              id="tradingview-widget-container"
              style={{ width: '100%', maxWidth: '1200px', height: '46px' }}
          ></div>
        </div>
      </div>
  );
};

export default Active;

