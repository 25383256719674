import { useEffect, useState } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import line from "../assets/line.svg";

const Active4 = ({ setActive4 }) => {
  const [cookies] = useCookies(["user", "pas"]);

  const [bots, setBots] = useState([]);

  const [dokupy, setDokupy] = useState([]);
  const [teyki, setTeyki] = useState([]);
  const [stop, setStop] = useState("");
  const [moneta, setMoneta] = useState("");
  const [monetaOpen, setMonetaOpen] = useState(false);
  const [kratnostPoslednegoOrdera, setKratnostPoslednegoOrdera] = useState("");

  const coins = [
    { id: "1", name: "OP", value: "OP" },
    { id: "2", name: "BNB", value: "BNB" },
    // Добавьте дополнительные монеты по необходимости
  ];

  useEffect(() => {
    axios("https://companytasks.ru/backend/register.php", {
      method: "POST",
      async: true,
      crossDomain: true,
      data: { user: cookies["user"], password: cookies["pas"] },
      headers: {
        accept:
          "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8",
        "accept-language": "en-US,en;q=0.9,ru;q=0.8",
        "content-type": "application/x-www-form-urlencoded",
      },
    })
      .then(function (response) {
        var r = response["data"];
        if (r["status"] !== "ok") {
          window.location.href = "/";
        } else {
          const botsRef = JSON.parse(response.data.data.bot_array).bots;

          botsRef.forEach((id) => {
            axios
              .get(
                `https://companytasks.ru/backend/get_data.php?id=${id}&user=${cookies["user"]}&password=${cookies["pas"]}`
              )
              .then((res) => {
                setBots((prev) => [...prev, res.data.data]);

                if (res.data.data.type === "3") {
                  const data = JSON.parse(res.data.data.params);

                  setDokupy(data.long_layers);
                  setTeyki(data.take);
                  setStop(data.stop);
                  setMoneta(data.coin);
                  setKratnostPoslednegoOrdera(data.layers);
                }
              });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://companytasks.ru/backend/register.php",
        {
          user: cookies["user"],
          password: cookies["pas"],
        },
        {
          headers: {
            accept:
              "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8",
            "accept-language": "en-US,en;q=0.9,ru;q=0.8",
            "content-type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(function (response) {
        const r = response.data;
        if (r.status !== "ok") {
          window.location.href = "/";
        } else {
          const botsRef = JSON.parse(response.data.data.bot_array).bots;
          const botPromises = botsRef.map((id) =>
            axios
              .get(
                `https://companytasks.ru/backend/get_data.php?id=${id}&user=${cookies["user"]}&password=${cookies["pas"]}`
              )
              .then((res) => res.data.data)
          );

          Promise.all(botPromises)
            .then((botData) => {
              setBots((prev) => [...prev, ...botData]);

              botData.forEach((bot) => {
                if (bot.type === "3") {
                  const data = JSON.parse(bot.params);
                  setDokupy(data.long_layers);
                  setTeyki(data.teyki);
                  setStop(data.stop);
                  setMoneta(data.coin);
                  setKratnostPoslednegoOrdera(data.layers);
                }
              });
            })
            .catch((error) => {
              console.log("Error fetching bot data:", error);
            });
        }
      })
      .catch((error) => {
        console.log("Error registering user:", error);
      });
  }, [cookies]); // Add `cookies` to dependencies

  const showMessage = (msg, type) =>
    toast[type](msg, {
      position: "top-center",
      autoClose: 2500,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
      hideProgressBar: true,
      theme: "dark",
    });

  const saveBot = (id) => {
    const params = JSON.stringify({
      long_layers: dokupy,
      take: teyki,
      stop: stop,
      coin: moneta,
      layers: kratnostPoslednegoOrdera,
    });

    confirmAlert({
      title: "Сохранить изменения?",
      message: "Вы уверены?",
      buttons: [
        {
          label: "Да",
          onClick: () => {
            axios
              .get(
                `https://companytasks.ru/backend/set_data.php?user=${cookies["user"]}&password=${cookies["pas"]}&params=${params}&id=${id}`
              )
              .then((res) => {
                setTimeout(() => {
                  setActive4(false);
                }, 1000);
                if (res.data.status === "ok") {
                  showMessage("Изменения сохранены!", "success");
                }
              });
          },
        },
        {
          label: "Нет",
        },
      ],
    });
  };

  const clearBot = (id) => {
    confirmAlert({
      title: "Установить рекомендуемые настройки?",
      message: "Вы уверены?",
      buttons: [
        {
          label: "Да",
          onClick: () => {
            axios
              .get(
                `https://companytasks.ru/backend/recomend.php?user=${cookies["user"]}&password=${cookies["pas"]}&id=${id}`
              )
              .then(() => {
                window.location.reload();
              });
          },
        },
        {
          label: "Нет",
        },
      ],
    });
  };

  return (
    <div className="flex overflow-auto max-h-[80vh] items-start justify-between flex-col md:flex-row w-full">
      <div>
        <div className="flex flex-wrap items-center justify-center md:justify-start gap-1">
          <span className="text-white md:text-start text-center w-full font-gilSemi text-[18px] md:w-[150px]">
            Докупы
          </span>
          {/* {[...Array(8)].map((_, i) => (
            <input
              type="text"
              onChange={(e) => {
                const updatedDokupy = [...dokupy];
                updatedDokupy[i] = e.target.value;
                setDokupy(updatedDokupy);
              }}
              value={dokupy && dokupy[i]}
              key={i}
              placeholder="Ввод"
              className="hit w-[87px] h-[48px] text-white flex items-center pl-5 ml-2 font-gil text-[14px] focus:outline-primary focus:outline"
            />
          ))} */}

          {[...Array(8)].map((_, i) => (
            <input
              type="text"
              onChange={(e) => {
                const updatedDokupy = dokupy ? [...dokupy] : Array(8).fill(""); // Agar `dokupy` bo'sh bo'lsa, yangi massiv yaratish
                updatedDokupy[i] = e.target.value;
                setDokupy(updatedDokupy);
              }}
              value={dokupy && dokupy[i] ? dokupy[i] : ""} // Agar `dokupy[i]` mavjud bo'lsa, uni ko'rsatadi, aks holda bo'sh string
              key={i}
              placeholder="Ввод"
              className="hit w-[87px] h-[48px] text-white flex items-center pl-5 ml-2 font-gil text-[14px] focus:outline-primary focus:outline"
            />
          ))}
        </div>

        <div className="flex flex-wrap items-center justify-center md:justify-start gap-1 mt-2">
          <span className="text-white md:text-start text-center w-full font-gilSemi text-[18px] md:w-[150px]">
            Тейки
          </span>
          {[...Array(9)].map((_, i) => (
            <input
              type="text"
              onChange={(e) => {
                const updatedTeyki = teyki ? [...teyki] : Array(9).fill("");

                updatedTeyki[i] = e.target.value;

                setTeyki(updatedTeyki);
              }}
              value={teyki && teyki[i]}
              key={i}
              placeholder="Ввод"
              className="hit w-[87px] h-[48px] text-white flex items-center pl-5 ml-2 font-gil text-[14px] focus:outline-primary focus:outline"
            />
          ))}
        </div>

        <div className="flex items-center justify-center md:justify-start mt-2 gap-1">
          <span className="text-white md:text-start text-center w-full font-gilSemi text-[18px] md:w-[150px]">
            Стоп Лосс
          </span>
          <input
            type="text"
            value={stop}
            onChange={(e) => setStop(e.target.value)}
            placeholder="Ввод"
            className="hit w-[87px] h-[48px] text-white flex items-center pl-5 ml-2 font-gil text-[14px] focus:outline-primary focus:outline"
          />
          <p className="text-white font-gilSemi text-[18px] ml-2 w-[150px]">
            %
          </p>
        </div>

        <div className="flex items-center relative justify-center md:justify-start mt-2 gap-1">
          <span className="text-white md:text-start text-center w-full font-gilSemi text-[18px] md:w-[150px]">
            Монета
          </span>
          <div
            onClick={() => setMonetaOpen((prev) => !prev)}
            className={`w-[180px] h-[48px] text-white items-center pl-3 md:ml-2 font-gil text-[14px] z-40 flex gap-5 ${
              monetaOpen ? "bg-zinc-900 rounded-xl" : "hit"
            }`}
          >
            {moneta || "Выбрать монету"}
            <img
              src={line}
              alt=""
              className={`${monetaOpen ? "rotate-180" : ""}`}
            />
          </div>

          {monetaOpen && (
            <div className="absolute left-[160px] z-40 top-[50px] bg-black pt-4 pb-2 w-[180px] rounded-xl">
              {coins.map((item) => (
                <div
                  onClick={() => {
                    setMonetaOpen(false);
                    setMoneta(item.name);
                  }}
                  key={item.id}
                  className="text-white font-gil px-2 hover:bg-primary transition-colors cursor-pointer rounded-[8px]"
                >
                  {item.name}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="flex items-center justify-center md:justify-start mt-2 gap-1">
          <span className="text-white md:text-start text-center w-full font-gilSemi text-[18px] md:w-[150px]">
            Кратность последнего ордера
          </span>
          <input
            type="text"
            value={kratnostPoslednegoOrdera}
            onChange={(e) => setKratnostPoslednegoOrdera(e.target.value)}
            placeholder="Ввод"
            className="hit w-[87px] h-[48px] text-white flex items-center pl-5 ml-2 font-gil text-[14px] focus:outline-primary focus:outline"
          />
        </div>
      </div>

      <div className="flex flex-col h-full justify-between items-end">
        <div className="flex flex-col h-full justify-between mt-10 items-end">
          <button
            onClick={() => clearBot(bots.filter((i) => i.type === "3")[0].id)}
            className="h-[36px] text-center right-2 text-xs w-[250px] absolute top-2 text-white button font-gilSemi"
          >
            Установить рекомендуемые настройки
          </button>
          <div></div>
          <button
            onClick={() => saveBot(bots.filter((i) => i.type === "3")[0].id)}
            className="button w-[157px] h-[56px] flex items-center justify-center text-white font-gilSemi text-[18px]"
          >
            Сохранить
          </button>
        </div>
      </div>
    </div>
  );
};

export default Active4;
