import React from "react";

const Bonuses = ({ className, title }) => {
    return (
        <div>
            
        </div>
    );
};

export default Bonuses;
