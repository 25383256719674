import React, { useCallback, useEffect, useState } from "react";
import "../index.css";
import logo from "../assets/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
// import online from "../assets/online.svg";
import pause from "../assets/pause.svg";
import continueImg from "../assets/continue.svg";
import set from "../assets/set.svg";
// import plus from "../assets/plus.svg";
import line from "../assets/line.svg";
// import what from "../assets/what.svg";
// import Modal from "react-modal";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useCookies } from "react-cookie";

import { ToastContainer, toast } from "react-toastify";
import Active1 from "./active1";
import Active2 from "./active2";
import Active4 from "./active4";

const settingPritorian = [
  {
    id: "1",
    name: "Long",
  },
  {
    id: "2",
    name: "Объемы",
  },
  {
    id: "3",
    name: "Монета",
  },
  //   {
  //     id: "4",
  //     name: "Тайминг",
  //   },
  {
    id: "5",
    name: "Take profit",
  },
  {
    id: "6",
    name: "Stop loss",
  },
  //   {
  //     id: "7",
  //     name: "DumP",
  //   },
];

const Settings = () => {
  const navigate = useNavigate();

  const [cookies] = useCookies(["user", "pas"]);

  const [active, setActive] = useState(true);
  const [timeOpen, setTimeOpen] = useState(false);
  const [monetaOpen, setMonetaOpen] = useState(false);

  //   const [selectedTime1, setSelectedTime1] = useState();
  //   const [selectedCoin1, setSelectedCoin1] = useState();

  //   const [selectedTime2, setSelectedTime2] = useState();
  //   const [selectedCoin2, setSelectedCoin2] = useState();

  const [selectedTime3, setSelectedTime3] = useState();
  const [selectedCoin3, setSelectedCoin3] = useState();

  const [pay_days, setPay_days] = useState();

  const [bot_id, setBot_id] = useState();

  const [status1, setStatus1] = useState(2);
  const [status2, setStatus2] = useState(2);
  const [status3, setStatus3] = useState(2);
  const [status4, setStatus4] = useState(2);

  const [pauseOrContinue1, setPauseOrContinue1] = useState(true);
  const [pauseOrContinue2, setPauseOrContinue2] = useState(true);
  const [pauseOrContinue3, setPauseOrContinue3] = useState(true);
  const [pauseOrContinue4, setPauseOrContinue4] = useState(true);

  const [isHave1, setHave1] = useState(false);
  const [isHave2, setHave2] = useState(false);
  const [isHave3, setHave3] = useState(false);
  const [isHave4, setHave4] = useState(false);
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);

  const [active4, setActive4] = useState(false);

  const [bots, setBots] = useState([]);

  const [multiplicity, setmultiplicity] = useState();

  const [time, setTime] = useState([
    {
      id: "1",
      name: "1h",
      value: "1h",
    },
    {
      id: "2",
      name: "4h",
      value: "4h",
    },
    {
      id: "3",
      name: "1d",
      value: "1d",
    },
  ]);

  const [moneta3, setMoneta] = useState([
    {
      id: "1",
      name: "BnB",
      value: "BnB",
    },
    {
      id: "2",
      name: "matic",
      value: "matic",
    },
    {
      id: "3",
      name: "mkr",
      value: "mkr",
    },
  ]);

  const [long_layers3, setLong_layers3] = useState([]);
  const [short_layers3, setShort_layers3] = useState([]);

  const [take_profit3, setTake_profit3] = useState([]);

  const [stop_loss3, setStop_loss3] = useState();
  const [dumP3, setdumP3] = useState();

  useEffect(() => {
    axios("https://companytasks.ru/backend/register.php", {
      method: "POST",
      async: true,
      crossDomain: true,
      data: { user: cookies["user"], password: cookies["pas"] },
      headers: {
        accept:
          "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8",
        "accept-language": "en-US,en;q=0.9,ru;q=0.8",
        "content-type": "application/x-www-form-urlencoded",
      },
    })
      .then(function (response) {
        var r = response["data"];
        if (r["status"] != "ok") {
          window.location.href = "/";
        } else {
          const botsRef = JSON.parse(response.data.data.bot_array).bots;

          setPay_days(response.data.data.pay_days);
          setBot_id(response.data.data.id_bot);

          botsRef.forEach((id) => {
            axios
              .get(
                `https://companytasks.ru/backend/get_data.php?id=${id}&user=${cookies["user"]}&password=${cookies["pas"]}`
              )
              .then((res) => {
                setBots((prev) => [...prev, res.data.data]);

                if (res.data.data.type == "0") {
                  setHave1(true);
                  setStatus1(res.data.data.is_work);
                  setPauseOrContinue1(
                    res.data.data.is_work == "1" ? true : false
                  );
                }

                if (res.data.data.type == "1") {
                  setHave2(true);
                  setStatus2(res.data.data.is_work);
                  setPauseOrContinue2(
                    res.data.data.is_work == "1" ? true : false
                  );
                }
                if (res.data.data.type == "2" && res.data.data.id == "-2") {
                  setHave3(true);
                  setStatus3(res.data.data.is_work);
                  setPauseOrContinue3(
                    res.data.data.is_work == "1" ? true : false
                  );

                  const data = JSON.parse(res.data.data.params);

                  setSelectedCoin3({ name: data.coin });
                  setSelectedTime3({ name: data.time });
                  setLong_layers3(data.long_layers);
                  console.log("data.long_layers", data.long_layers);
                  setShort_layers3(data.volumes_glav);
                  setStop_loss3(data.stop);
                  setdumP3(data.dump);

                  setmultiplicity(data.multiplicity);

                  setTake_profit3(data.take);
                }

                if (res.data.data.type == "3") {
                  setHave4(true);
                  setStatus4(res.data.data.is_work);
                  setPauseOrContinue4(
                    res.data.data.is_work == "1" ? true : false
                  );
                }
              });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleActive2 = useCallback(() => setActive((prev) => !prev), []);

  const showMessage = (msg, type) =>
    toast[type](msg, {
      position: "top-center",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
      hideProgressBar: true,
      theme: "dark",
    });

  const stopBot = (id) => {
    confirmAlert({
      title: "Отановить бота",
      message: "Вы уверены?",
      buttons: [
        {
          label: "Да",
          onClick: () => {
            axios
              .get(
                `https://companytasks.ru/backend/stop.php?id=${id}&user=${cookies["user"]}&password=${cookies["pas"]}`
              )
              .then((response) => {
                if (response.data.status == "ok") {
                  showMessage("Успешно приостановлен!", "success");
                } else {
                  showMessage("Произовла ошибка!", "error");
                }
              })
              .then(() => {
                axios
                  .get(
                    `https://companytasks.ru/backend/get_data.php?id=${id}&user=${cookies["user"]}&password=${cookies["pas"]}`
                  )
                  .then((res) => {
                    if (res.data.data.type == "1") {
                      setStatus2(res.data.data.is_work);
                      setPauseOrContinue2(
                        !res.data.data.is_work == 1 ? true : false
                      );
                    }
                    if (res.data.data.type == "2") {
                      setStatus3(res.data.data.is_work);
                      setPauseOrContinue3(
                        !res.data.data.is_work == 1 ? true : false
                      );
                    }
                    if (res.data.data.type == "0") {
                      setStatus1(res.data.data.is_work);
                      setPauseOrContinue1(
                        !res.data.data.is_work == 1 ? true : false
                      );
                    }
                  });
              });
          },
        },
        {
          label: "Нет",
        },
      ],
    });
  };

  const startBot = (id) => {
    confirmAlert({
      title: "Запустить бота ?",
      message: "Вы уверены?",
      buttons: [
        {
          label: "Да",
          onClick: () => {
            axios
              .get(
                `https://companytasks.ru/backend/start.php?id=${id}&user=${cookies["user"]}&password=${cookies["pas"]}`
              )
              .then((response) => {
                if (response.data.status == "ok") {
                  showMessage("Успешно запустился!", "success");
                } else {
                  showMessage("Произовла ошибка!", "error");
                }
              })
              .then(() => {
                axios
                  .get(
                    `https://companytasks.ru/backend/get_data.php?id=${id}&user=${cookies["user"]}&password=${cookies["pas"]}`
                  )
                  .then((resp) => {
                    if (resp.data.data.type == "1") {
                      setStatus2(resp.data.data.is_work);
                      setPauseOrContinue2(
                        resp.data.data.is_work == "1" ? true : false
                      );
                    }
                    if (resp.data.data.type == "2") {
                      setStatus3(resp.data.data.is_work);
                      setPauseOrContinue3(
                        resp.data.data.is_work == "1" ? true : false
                      );
                    }
                    if (resp.data.data.type == "0") {
                      setStatus1(resp.data.data.is_work);
                      setPauseOrContinue1(
                        resp.data.data.is_work == "1" ? true : false
                      );
                    }
                  });
              });
          },
        },
        {
          label: "Нет",
        },
      ],
    });
  };

  const saveBot = (id) => {
    const params = JSON.stringify({
      coin: selectedCoin3.name,
      time: selectedTime3.name,
      dump: parseFloat(dumP3),
      take: take_profit3.map((i) => parseFloat(i)),
      stop: parseFloat(stop_loss3),
      volumes_glav: short_layers3 && short_layers3.map((i) => parseFloat(i)),
      long_layers: long_layers3 && long_layers3.map((i) => parseFloat(i)),
      multiplicity: parseFloat(multiplicity),
    });

    confirmAlert({
      title: "Сохранить изменения ?",
      message: "Вы уверены?",
      buttons: [
        {
          label: "Да",
          onClick: () => {
            axios
              .get(
                `https://companytasks.ru/backend/set_data.php?user=${cookies["user"]}&password=${cookies["pas"]}&params=${params}&id=${id}`
              )
              .then((res) => {
                setTimeout(() => {
                  setActive3(false);
                }, 1000);
                if (res.data.status == "ok") {
                  showMessage("Изменения сохранены!", "success");
                }
              });
          },
        },
        {
          label: "Нет",
        },
      ],
    });
  };

  const handleTimeClick = (item) => {
    // Handle click on the time block
    setSelectedTime3(item);
    console.log(item, !time);
    setTimeOpen(!timeOpen);
    // You can perform additional actions if needed
  };
  const handleActive = (item) => {
    // Handle click on the time block
    handleActive2(item);
    console.log(item);
    // You can perform additional actions if needed
  };
  const handleActive1 = (item) => {
    // Handle click on the time block
    console.log(item);
    // You can perform additional actions if needed
  };
  const handleCoinClick = (item) => {
    // Handle click on the time block
    setSelectedCoin3(item);
    setMonetaOpen(!monetaOpen);
    // You can perfosrm additional actions if needed
  };

  function setCookiePath(name, value, options = {}) {
    options = {
      path: "/",
      // при необходимости добавьте другие значения по умолчанию
      ...options,
    };

    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }

    let updatedCookie =
      encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
      updatedCookie += "; " + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += "=" + optionValue;
      }
    }

    document.cookie = updatedCookie;
  }

  const leaveAccount = () => {
    setCookiePath("user", "", {
      "max-age": -1,
    });
    setCookiePath("pas", "", {
      "max-age": -1,
    });
    navigate("/");
  };

  useEffect(() => {
    if (!cookies["user"]) {
      navigate("/");
    }
  }, []);

  const openModal = (id, event) => {
    if (id == 1) {
      if (event?.target.classList.contains("modal_out")) {
        setActive1(false);
      } else {
        setActive1(true);
      }
    }
    if (id == 2) {
      if (event?.target.classList.contains("modal_out")) {
        setActive2(false);
      } else {
        setActive2(true);
      }
    }
    if (id == 3) {
      if (event?.target.classList.contains("modal_out")) {
        setActive3(false);
      } else {
        setActive3(true);
      }
    }
    if (id == 4) {
      if (event?.target.classList.contains("modal_out")) {
        setActive4(false);
      } else {
        setActive4(true);
      }
    }
  };

  const clearBot = (id) => {
    confirmAlert({
      title: "Установить рекомендуемые настройки ?",
      message: "Вы уверены?",
      buttons: [
        {
          label: "Да",
          onClick: () => {
            axios
              .get(
                `https://companytasks.ru/backend/recomend.php?user=${cookies["user"]}&password=${cookies["pas"]}&id=${id}`
              )
              .then(() => {
                window.location.reload();
              });
          },
        },
        {
          label: "Нет",
        },
      ],
    });
  };

  const clickBtn = () => {
    console.log("click", take_profit3);
  };
  return (
    <div className=" third flex flex-col items-center">
      <ToastContainer />

      <header className="flex flex-col items-center px-2 pt-10 sm:hidden">
        <img src={logo} alt="logo" />
        <div className="flex gap-10">
          <Link to="/" className=" font-gil text-[18px] text-white">
            Перейти к лендингу
          </Link>
          <button
            onClick={leaveAccount}
            className="button flex items-center px-5 justify-center font-gil text-[18px] text-white"
          >
            Выход
          </button>
        </div>
      </header>
      <header className="hidden sm:flex items-center justify-between w-full mt-10 px-10">
        <Link to="/" className=" font-gil text-[22px] text-white">
          Перейти к лендингу
        </Link>
        <img src={logo} alt="logo" />
        <button
          onClick={leaveAccount}
          className="button flex items-center px-5 justify-center font-gil text-[18px] w-[117px] h-[56px] text-white"
        >
          Выход
        </button>
      </header>

      {isHave1 && (
        <div className="block flex justify-between w-full items-center py-10 px-2 md:px-10 gg:mt-16 mt-6">
          <h2 className="text-xl md:text-[32px] flex-1 font-jura font-bold text-white gg:text-[48px]">
            Spartan
          </h2>
          <div className="flex gap-5 flex-1 gg:gap-16">
            <span className="text-xl md:text-[24px] text-white font-gilSemi gg:text-[32px]">
              Срок в днях {pay_days}
            </span>
          </div>
          <div className="flex md:flex-row flex-col items-center gap-5 gg:gap-16">
            {status1 == 0 ? (
              <div className="w-7 h-7 rounded-full bg-orange-400"></div>
            ) : status1 == 1 ? (
              <div className="w-7 h-7 rounded-full bg-green-500"></div>
            ) : status1 == -2 ? (
              <div className="w-7 h-7 rounded-full bg-red"></div>
            ) : (
              status1 == 2 && <div className="w-7 h-7 rounded-full"></div>
            )}
            {pauseOrContinue1 ? (
              <img
                onClick={() => stopBot(bots.filter((i) => i.type == "0")[0].id)}
                src={pause}
                alt="icon"
                className="w-7 h-7 cursor-pointer"
              />
            ) : (
              <img
                onClick={() =>
                  startBot(bots.filter((i) => i.type == "0")[0].id)
                }
                src={continueImg}
                alt="icon"
                className="w-6 h-6 cursor-pointer"
              />
            )}
            <img
              src={set}
              alt="icon"
              onClick={() => openModal(1)}
              className="w-7 h-7 cursor-pointer"
            />
          </div>
        </div>
      )}

      {isHave2 && (
        <div className="block flex justify-between w-full items-center py-10 px-2 md:px-10 gg:mt-8 mt-2">
          <h2 className="text-xl md:text-[32px] flex-1 font-jura font-bold text-white gg:text-[48px]">
            Spartan Lite
          </h2>
          <div className="flex gap-5 flex-1 gg:gap-16">
            <span className="text-xl md:text-[24px] text-white font-gilSemi gg:text-[32px]">
              Срок в днях {pay_days}
            </span>
          </div>
          <div className="flex md:flex-row flex-col items-center gap-5 gg:gap-16">
            {status2 == 0 ? (
              <div className="w-7 h-7 rounded-full bg-orange-400"></div>
            ) : status2 == 1 ? (
              <div className="w-7 h-7 rounded-full bg-green-500"></div>
            ) : status2 == -2 ? (
              <div className="w-7 h-7 rounded-full bg-red"></div>
            ) : (
              status2 == 2 && <div className="w-7 h-7 rounded-full"></div>
            )}
            {pauseOrContinue2 ? (
              <img
                onClick={() => stopBot(bots.filter((i) => i.type == "1")[0].id)}
                src={pause}
                alt="icon"
                className="w-7 h-7 cursor-pointer"
              />
            ) : (
              <img
                onClick={() =>
                  startBot(bots.filter((i) => i.type == "1")[0].id)
                }
                src={continueImg}
                alt="icon"
                className="w-6 h-6 cursor-pointer"
              />
            )}
            <img
              src={set}
              alt="icon"
              onClick={() => openModal(2)}
              className="w-7 h-7 cursor-pointer"
            />
          </div>
        </div>
      )}

      {isHave3 && (
        <div className="block flex justify-between w-full items-center py-10 px-2 md:px-10 gg:mt-8 mt-2">
          <h2 className="text-xl md:text-[32px] flex-1 font-jura font-bold text-white gg:text-[48px]">
            Gladiator
          </h2>
          <div className="flex gap-5 flex-1 gg:gap-16">
            <span className="text-xl md:text-[24px] text-white font-gilSemi gg:text-[32px]">
              Срок в днях {pay_days}
            </span>
          </div>
          <div className="flex md:flex-row flex-col items-center gap-5 gg:gap-16">
            {status3 == 0 ? (
              <div className="w-7 h-7 rounded-full bg-orange-400"></div>
            ) : status3 == 1 ? (
              <div className="w-7 h-7 rounded-full bg-green-500"></div>
            ) : status3 == -2 ? (
              <div className="w-7 h-7 rounded-full bg-red"></div>
            ) : (
              status3 == 2 && <div className="w-7 h-7 rounded-full"></div>
            )}
            {pauseOrContinue3 ? (
              <img
                onClick={() => stopBot(bots.filter((i) => i.type == "2")[0].id)}
                src={pause}
                alt="icon"
                className="w-7 h-7 cursor-pointer"
              />
            ) : (
              <img
                onClick={() =>
                  startBot(bots.filter((i) => i.type == "2")[0].id)
                }
                src={continueImg}
                alt="icon"
                className="w-6 h-6 cursor-pointer"
              />
            )}
            <img
              src={set}
              alt="icon"
              onClick={() => openModal(3)}
              className="w-7 h-7 cursor-pointer"
            />
          </div>
        </div>
      )}

      {isHave4 && (
        <div className="block flex justify-between w-full items-center py-10 px-2 md:px-10 gg:mt-8 mt-2">
          <h2 className="text-xl md:text-[32px] flex-1 font-jura font-bold text-white gg:text-[48px]">
            Название четвертого советника
          </h2>
          <div className="flex gap-5 flex-1 gg:gap-16">
            <span className="text-xl md:text-[24px] text-white font-gilSemi gg:text-[32px]">
              Срок в днях {pay_days}
            </span>
          </div>
          <div className="flex md:flex-row flex-col items-center gap-5 gg:gap-16">
            {status4 == 0 ? (
              <div className="w-7 h-7 rounded-full bg-orange-400"></div>
            ) : status4 == 1 ? (
              <div className="w-7 h-7 rounded-full bg-green-500"></div>
            ) : status4 == -2 ? (
              <div className="w-7 h-7 rounded-full bg-red"></div>
            ) : (
              status4 == 2 && <div className="w-7 h-7 rounded-full"></div>
            )}
            {pauseOrContinue4 ? (
              <img
                onClick={() => stopBot(bots.filter((i) => i.type == "3")[0].id)}
                src={pause}
                alt="icon"
                className="w-7 h-7 cursor-pointer"
              />
            ) : (
              <img
                onClick={() =>
                  startBot(bots.filter((i) => i.type == "3")[0].id)
                }
                src={continueImg}
                alt="icon"
                className="w-6 h-6 cursor-pointer"
              />
            )}
            <img
              src={set}
              alt="icon"
              onClick={() => openModal(4)}
              className="w-7 h-7 cursor-pointer"
            />
          </div>
        </div>
      )}

      {active1 === true ? (
        <div
          onClick={(e) => openModal(1, e)}
          className="fixed modal_out left-0 top-0 flex justify-center items-center w-full h-screen"
        >
          <div className="blockInf flex px-5 py-10 gg:w-[1328px] gg:justify-between gg:px-16">
            <p className="absolute top-2 bg-[#ffffff42] modal_out rounded-full flex items-center justify-center pb-[6px] pl-[1px] cursor-pointer w-[36px] h-[36px] left-3 text-white rotate-45 text-2xl">
              +
            </p>

            <Active1 setActive1={setActive1} />
          </div>
        </div>
      ) : (
        <div className="flex justify-start w-full px-[20px]"></div>
      )}

      {active2 === true ? (
        <div
          onClick={(e) => openModal(2, e)}
          className="fixed modal_out left-0 top-0 flex justify-center items-center w-full h-screen"
        >
          <div className="blockInf flex px-5 py-10 gg:w-[1328px] gg:justify-between gg:px-16">
            <p className="absolute top-2 bg-[#ffffff42] modal_out rounded-full flex items-center justify-center pb-[6px] pl-[1px] cursor-pointer w-[36px] h-[36px] left-3 text-white rotate-45 text-2xl">
              +
            </p>

            <Active2 setActive1={setActive2} />
          </div>
        </div>
      ) : (
        <div className="flex justify-start w-full px-[20px]"></div>
      )}

      {active3 === true ? (
        <div
          onClick={(e) => openModal(3, e)}
          className="fixed modal_out left-0 top-0 flex justify-center items-center w-full h-screen"
        >
          <div className="blockInf flex px-5 py-10 gg:w-[1328px] gg:justify-between flex-col md:flex-row gg:px-16">
            <p className="absolute top-2 bg-[#ffffff42] modal_out rounded-full flex items-center justify-center pb-[6px] pl-[1px] cursor-pointer w-[36px] h-[36px] left-3 text-white rotate-45 text-2xl">
              +
            </p>

            <div className="flex overflow-auto max-h-[70vh] items-start justify-between flex-col w-full">
              {settingPritorian.map((item) => (
                <div
                  key={item.id}
                  className="flex  items-start justify-center md:justify-start mt-2 gap-1"
                >
                  <span
                    className={`${
                      item.id == "2" && "hidden "
                    } text-white md:text-start text-center w-full font-gilSemi text-[18px] md:w-[150px]`}
                  >
                    {item.name}
                  </span>
                  <div className="flex">
                    {item.id === "1" || item.id === "2" ? (
                      <>
                        <div className="flex justify-center flex-wrap gg:hidden">
                          {Array.from(
                            { length: item.id === "2" ? 0 : 0 },
                            (_, index) => (
                              <input
                                value={
                                  item.id === "1"
                                    ? long_layers3 != undefined
                                      ? long_layers3[index]
                                      : null
                                    : short_layers3 != undefined
                                    ? short_layers3[index]
                                    : null
                                }
                                onChange={(e) => {
                                  if (item.id == "1") {
                                    if (long_layers3 != undefined) {
                                      const newArr = (long_layers3[index] =
                                        e.target.value);
                                      const totalArr =
                                        long_layers3.concat(newArr);
                                      setLong_layers3(totalArr);
                                    } else {
                                      let arr = [];
                                      arr[index] = e.target.value;
                                      setLong_layers3(arr);
                                    }
                                  } else {
                                    if (short_layers3 != undefined) {
                                      setShort_layers3((prev) => [
                                        ...prev,
                                        (short_layers3[index] = e.target.value),
                                      ]);
                                    } else {
                                      let arr = [];
                                      arr[index] = e.target.value;
                                      setShort_layers3(arr);
                                    }
                                  }
                                }}
                                key={index}
                                type="text"
                                name="text"
                                id="text"
                                placeholder="Ввод"
                                className={`hit w-[87px] h-[48px] text-white flex items-center pl-3 font-gil text-[14px]  focus:outline-primary focus:outline`}
                              />
                            )
                          )}
                        </div>
                        {item.id != "1" && (
                          <div className="my-1 flex items-center justify-start">
                            <span className="text-white md:text-start text-center w-full font-gilSemi text-[18px] md:w-[145px]">
                              Кратность
                            </span>
                            <input
                              type="text"
                              value={multiplicity}
                              onChange={(e) => setmultiplicity(e.target.value)}
                              placeholder="Ввод"
                              className="hit w-[87px] h-[48px] text-white flex items-center pl-5 ml-2 font-gil text-[14px]  focus:outline-primary focus:outline"
                            />
                          </div>
                        )}

                        <div className="flex flex-wrap items-center justify-center md:justify-start mt-2 gap-3">
                          {Array.from(
                            {
                              length: long_layers3.length,
                            },
                            (_, index) => (
                              <input
                                value={
                                  item.id === "1"
                                    ? long_layers3 != undefined
                                      ? long_layers3[index]
                                      : null
                                    : short_layers3 != undefined
                                    ? short_layers3[index]
                                    : null
                                }
                                onChange={(e) => {
                                  if (item.id == "1") {
                                    if (long_layers3 != undefined) {
                                      setLong_layers3((prev) => [
                                        ...prev,
                                        (long_layers3[index] = e.target.value),
                                      ]);
                                      long_layers3.length = 4;
                                    } else {
                                      let arr = [];
                                      arr[index] = e.target.value;
                                      setLong_layers3(arr);
                                    }
                                  } else {
                                    if (short_layers3 != undefined) {
                                      const newArr = (short_layers3[index] =
                                        e.target.value);
                                      const totalArr =
                                        short_layers3.concat(newArr);
                                      setShort_layers3(totalArr);
                                    } else {
                                      let arr = [];
                                      arr[index] = e.target.value;
                                      setShort_layers3(arr);
                                    }
                                  }
                                }}
                                key={index}
                                type="text"
                                name="text"
                                id="text"
                                placeholder="Ввод"
                                className={`${
                                  item.id == "2" && "hidden "
                                } hit w-[87px] h-[48px] text-white flex items-center pl-3 font-gil text-[14px]  focus:outline-primary focus:outline`}
                              />
                            )
                          )}
                        </div>
                      </>
                    ) : item.id === "5" || item.id === "6" ? (
                      <div className="flex items-center flex-wrap justify-center gap-4 text-white text-[20px]">
                        {item.id == "5" ? (
                          take_profit3?.map((i, index) => (
                            <div className="flex flex-wrap items-center justify-center md:justify-start mt-2 gap-1">
                              <input
                                value={
                                  take_profit3 != undefined
                                    ? take_profit3[i]
                                    : null
                                }
                                onChange={(e) => {
                                  if (take_profit3 != undefined) {
                                    setTake_profit3((prev) => [
                                      ...prev,
                                      (take_profit3[i] = e.target.value),
                                    ]);
                                    take_profit3.length = 5;
                                  } else {
                                    let arr = [];
                                    arr[index] = e.target.value;
                                    setTake_profit3(arr);
                                  }
                                }}
                                key={i}
                                type="text"
                                name="text"
                                id="text"
                                placeholder="Ввод"
                                className="hit w-[87px] h-[48px] text-white flex items-center pl-3 font-gil text-[14px]  focus:outline-primary focus:outline"
                              />
                              %
                            </div>
                          ))
                        ) : (
                          <input
                            value={stop_loss3}
                            onChange={(e) => setStop_loss3(e.target.value)}
                            type="text"
                            name="text"
                            id="text"
                            placeholder="Ввод"
                            className="hit w-[87px] h-[48px] text-white flex items-center pl-3 font-gil text-[14px]  focus:outline-primary focus:outline"
                          />
                        )}
                      </div>
                    ) : item.id === "7" ? (
                      <div className="flex gap-5">
                        <div className="flex items-center text-white text-[20px] gap-3">
                          <input
                            value={dumP3}
                            onChange={(e) => setdumP3(e.target.value)}
                            type="text"
                            name="text"
                            id="text"
                            placeholder="Ввод"
                            className="hit w-[87px] h-[48px] text-white flex items-center pl-3 font-gil text-[14px]  focus:outline-primary focus:outline"
                          />
                          %
                        </div>
                      </div>
                    ) : item.id === "4" ? (
                      <div
                        onClick={() => setTimeOpen((prev) => !prev)}
                        className={` w-[180px] h-[48px] text-white  items-center pl-3 font-gil text-[14px] z-40 flex gap-5 ${
                          time === true ? "bg-zinc-900 rounded-xl" : "hit"
                        }`}
                      >
                        {selectedTime3 !== null
                          ? selectedTime3.name
                          : "Выбрать время"}
                        <img
                          src={line}
                          alt=""
                          className={`${time === true ? " rotate-180" : ""}`}
                        />
                      </div>
                    ) : (
                      <div
                        onClick={() => setMonetaOpen((prev) => !prev)}
                        className={`w-[180px] h-[48px] text-white items-center pl-3 font-gil text-[14px] flex gap-5 z-20 ${
                          monetaOpen === true ? "bg-zinc-900 rounded-xl" : "hit"
                        }`}
                      >
                        {selectedCoin3 !== null
                          ? selectedCoin3.name
                          : "Выбрать монету"}
                        <img
                          src={line}
                          alt=""
                          className={`${
                            monetaOpen === true ? " rotate-180" : ""
                          }`}
                        />
                      </div>
                    )}
                    <div
                      className={`${
                        item.id === "5" ? "relative z-30" : "hidden"
                      } z-[99999999999]`}
                    >
                      {timeOpen && (
                        <div className="absolute -left-[625px] -top-[5px] bg-black pt-4 pb-2 w-[180px] rounded-xl">
                          {time.map((item) => (
                            <div
                              onClick={() => {
                                setTimeOpen(false);
                                setSelectedTime3(item);
                              }}
                              key={item.id}
                              className="text-white font-gil px-2 hover:bg-primary transition-colors cursor-pointer rounded-[8px]"
                            >
                              {item.name}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div
                      className={`${
                        item.id === "6" ? "relative" : "hidden"
                      } z-[99999999999]`}
                    >
                      {monetaOpen && (
                        <div className="absolute -left-[100px] -top-[115px] bg-black pt-4 pb-2 w-[180px] rounded-xl">
                          {moneta3.map((item) => (
                            <div
                              onClick={() => {
                                setMonetaOpen(false);
                                setSelectedCoin3(item);
                              }}
                              key={item.id}
                              className="text-white font-gil px-2 hover:bg-primary transition-colors cursor-pointer rounded-[8px]"
                            >
                              {item.name}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex flex-col w-fit mt-3 justify-between items-end">
              <div className="flex flex-col h-full justify-between items-end">
                <button
                  onClick={() =>
                    clearBot(bots.filter((i) => i.type == "2")[0].id)
                  }
                  className="h-[36px] text-center right-2 text-xs w-[250px] absolute top-2 text-white button font-gilSemi"
                >
                  Установить рекомендуемые настройки
                </button>
                <div></div>
                <button
                  onClick={() =>
                    saveBot(bots.filter((i) => i.type == "2")[0].id)
                  }
                  className="button w-[157px] h-[56px] flex items-center justify-center text-white font-gilSemi text-[18px]"
                >
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-start w-full px-[20px]"></div>
      )}

      {active4 === true ? (
        <div
          onClick={(e) => openModal(4, e)}
          className="fixed modal_out left-0 top-0 flex justify-center items-center w-full h-screen"
        >
          <div className="blockInf flex px-5 py-10 gg:w-[1328px] gg:justify-between gg:px-16">
            <p className="absolute top-2 bg-[#ffffff42] modal_out rounded-full flex items-center justify-center pb-[6px] pl-[1px] cursor-pointer w-[36px] h-[36px] left-3 text-white rotate-45 text-2xl">
              +
            </p>

            <Active4 setActive4={setActive4} />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Settings;
