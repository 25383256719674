import React from "react";
import logo from "../assets/logo.svg";
import min from "../assets/min.svg";
import max from "../assets/max.svg";

const links = [
  {
    id: "1",
    name: "ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ",
    link: "/Пользовательское соглашение.docx",
  },
  {
    id: "2",
    name: "ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ",
    link: "/Политика конфиденциальности.docx",
  },
  {
    id: "3",
    name: "ПОЛОЖЕНИЯ И УСЛОВИЯ ИСПОЛЬЗОВАНИЯ ПРОГРАММНЫХ ПРОДУКТОВ",
    link: "/Положения_и_условия_использования_программных_продуктов.docx",
  },
  {
    id: "4",
    name: "СОГЛАСИЕ НА ОБРАТОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ",
    link: "/Согласие_на_обработку_персональных_данных.docx",
  },
  {
    id: "5",
    name: "ПРАВИЛА ВОЗВРАТА ПРОГРАММНОГО ОБЕСПЕЧЕНИЯ",
    link: "/Правила_возврата_программного_обеспечения.docx",
  },
];
const Footer = ({ className }) => {
  return (
    <div className={className}>
      <div className="flex justify-center items-center flex-col gap-10 md:items-start">
        <img src={logo} alt="logo" />
        <div className="flex items-center gap-10">
          <a href="mailto:admin@projectimperial.ru" target="_blank">
            <img src={min} alt="icon" />
          </a>
          <a
            target="_blank"
            rel="noopener-noreferrer"
            href="https://t.me/Project_Imperial"
          >
            <img src={max} alt="icon" />
          </a>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        {links.map((item) => (
          <a
            target="_blank"
            href={item.link}
            key={item.id}
            className="font-gil text-base text-white md:text-end"
          >
            {item.name}
          </a>
        ))}
        
      </div>
    </div>
  );
};

export default Footer;
